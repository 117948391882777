<template>
    <BaseTemplate app-name="Whatsapp" page-title="">
        <template #content>
            <div v-if="isLoading" class="flex flex-col items-center justify-center min-h-[500px]">
                <RefreshCw class="h-16 w-16 animate-spin text-blue-600 dark:text-blue-400 mx-auto" />
            </div>

            <!-- Mensagem caso não haja instâncias -->
            <div v-else-if="instances.length === 0"
                class="flex flex-col items-center justify-center min-h-[500px] bg-gray-50 dark:bg-gray-900 rounded-lg p-6 transition-colors duration-300">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-24 w-24 text-gray-400 dark:text-gray-600 mb-4"
                    fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <p class="text-2xl font-semibold text-gray-600 dark:text-gray-300">Nenhuma instância encontrada</p>
                <p class="text-gray-500 dark:text-gray-500 mt-2">Adicione uma nova instância para começar</p>
            </div>

            <!-- Exibir os cards somente quando não estiver carregando e houver instâncias -->
            <div v-else class="p-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-8">
                <div v-for="instance in instances" :key="instance.id"
                    class="bg-white dark:bg-gray-800 shadow-lg rounded-2xl overflow-hidden transform transition-all duration-300 hover:scale-[1.03] hover:shadow-2xl border border-gray-100 dark:border-gray-700">
                    <!-- Botão "Ver métricas" -->
                    <button v-if="instance.accountId"
                        class="absolute top-2 right-2 flex items-center space-x-2 bg-blue-100 dark:bg-blue-800 text-gray-800 dark:text-gray-200 px-3 py-1 rounded-full text-sm font-semibold shadow-md hover:bg-gray-300 dark:hover:bg-gray-600 transition-all"
                        @click="viewMetrics(instance.accountId)">
                        <span>Ver métricas</span>
                        <MoveRight class="w-4 h-4" />
                    </button>
                    <p v-else class="absolute top-2 right-2 text-sm text-red-500">ID de métrica indisponível</p>


                    <div class="p-6 flex flex-col items-center">
                        <div class="relative mb-5 mt-5">
                            <img :src="instance.profilePicUrl || DisconnectIcon " alt="Profile Picture"
                                style="width: 64px; height: 64px; user-select: none;"
                                class="w-20 h-20 rounded-full object-cover border-4 shadow-md dark:bg-blue-200" :class="{
                                    'border-green-500': instance.connectionStatus === 'open',
                                    'border-red-500': instance.connectionStatus === 'close' || instance.connectionStatus === 'connecting',
                                }" @error="fallbackImage($event)" />
                            <span class="absolute -bottom-1 -right-1 w-7 h-7 rounded-full border-3 border-white" :class="{
                                'bg-green-500': instance.connectionStatus === 'open',
                                'bg-red-500': instance.connectionStatus === 'close' || instance.connectionStatus === 'connecting',
                            }"></span>
                        </div>
                        <h2
                            class="text-xl font-bold text-gray-800 dark:text-gray-200 mb-3 text-center truncate max-w-full uppercase">
                            {{ instance.name }}
                        </h2>
                        <h2 class="text-sm uppercase tracking-wide text-gray-800 dark:text-gray-200 font-semibold mb-4">
                            {{ formatPhoneNumber(instance.ownerJid) || 'Sem ID de proprietário' }}
                        </h2>

                        <p class="text-sm uppercase tracking-wide font-semibold mb-4" :class="{
                            'text-green-600 dark:text-green-400': instance.connectionStatus === 'open',
                            'text-red-600 dark:text-red-400': instance.connectionStatus === 'close' || instance.connectionStatus === 'connecting',
                        }">
                            {{ instance.connectionStatus === 'open' ? 'Conectado' : instance.connectionStatus ===
                            'connecting' ? 'Desconectado' : 'Desconectado' }}
                        </p>
                        <div class="w-full grid grid-cols-2 gap-4 border-t border-gray-200 dark:border-gray-700 pt-4">
                            <!-- Online Users -->
                            <div class="flex flex-col items-center relative">
                                <div class="flex items-center space-x-2 mb-1 cursor-pointer"
                                    @mouseover="showTooltip('online', instance.id)"
                                    @mouseout="hideTooltip('online', instance.id)"
                                    @touchstart="showTooltip('online', instance.id)"
                                    @touchend="hideTooltip('online', instance.id)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="h-5 w-5 text-green-500 lucide lucide-user-round-check">
                                        <path d="M2 21a8 8 0 0 1 13.292-6" />
                                        <circle cx="10" cy="8" r="5" />
                                        <path d="m16 19 2 2 4-4" />
                                    </svg>
                                    <span class="text-green-600 dark:text-green-400 font-bold">{{ instance.activeUsers
                                        }}</span>
                                </div>
                                <p class="text-xs text-gray-500 dark:text-gray-400">Online</p>

                            </div>
                            <!-- Offline Users -->
                            <div class="flex flex-col items-center relative">
                                <div class="flex items-center space-x-2 mb-1 cursor-pointer"
                                    @mouseover="showTooltip('offline', instance.id)"
                                    @mouseout="hideTooltip('offline', instance.id)"
                                    @touchstart="showTooltip('offline', instance.id)"
                                    @touchend="hideTooltip('offline', instance.id)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="lucide lucide-user-round-x h-5 w-5 text-red-500">
                                        <path d="M2 21a8 8 0 0 1 11.873-7" />
                                        <circle cx="10" cy="8" r="5" />
                                        <path d="m17 17 5 5" />
                                        <path d="m22 17-5 5" />
                                    </svg>
                                    <span class="text-red-600 dark:text-red-400 font-bold">{{ instance.inactiveUsers
                                        }}</span>
                                </div>
                                <p class="text-xs text-gray-500 dark:text-gray-400">Offline</p>
                            </div>
                        </div>



                        <div v-if="tooltips.online === instance.id" @mouseenter="keepTooltipOpen('online', instance.id)"
                            @mouseleave="hideTooltip('online', instance.id)"
                            class="absolute top-10 left-1/2 transform -translate-x-1/2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-lg p-3 text-sm z-50 w-48 max-h-48 overflow-auto">
                            <p class="font-bold text-green-600 dark:text-green-400 mb-2">Usuários Online</p>
                            <ul>
                                <li v-for="user in instance.onlineUsers" :key="user.id"
                                    class="text-gray-600 dark:text-gray-300">
                                    {{ user.name }}
                                </li>
                                <li v-if="instance.onlineUsers.length === 0" class="text-gray-400 dark:text-gray-500">
                                    Nenhum usuário online
                                </li>
                            </ul>
                        </div>

                        <div v-if="tooltips.offline === instance.id"
                            @mouseenter="keepTooltipOpen('offline', instance.id)"
                            @mouseleave="hideTooltip('offline', instance.id)"
                            class="absolute top-10 left-1/2 transform -translate-x-1/2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-lg p-3 text-sm z-50 w-48 max-h-48 overflow-auto">
                            <p class="font-bold text-red-600 dark:text-red-400 mb-2">Usuários Offline</p>
                            <ul>
                                <li v-for="user in instance.offlineUsers" :key="user.id"
                                    class="text-gray-600 dark:text-gray-300">
                                    {{ user.name }}
                                </li>
                                <li v-if="instance.offlineUsers.length === 0" class="text-gray-400 dark:text-gray-500">
                                    Nenhum usuário offline
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>



            <!-- Rodapé fixo -->
            <p class="text-center text-sm mb-6 text-gray-500 dark:text-gray-400">
                © 2025 T.I CCDTI. Todos os direitos reservados.
            </p>
        </template>
    </BaseTemplate>
</template>

<script setup>
import DisconnectIcon from '@/assets/disconnect.jpg'
import BaseTemplate from './BaseTemplate.vue';
import { ref, onMounted, onUnmounted } from 'vue';
import { MoveRight, RefreshCw } from 'lucide-vue-next';
import { useRouter } from 'vue-router';

// Inicialize o roteador
const router = useRouter();


let intervalId;
const instances = ref([]);
const isLoading = ref(true); // Variável para controlar o estado de carregamento
const EVOLUTION_API = process.env.VUE_APP_EVOLUTION_API;
const EVOLUTION_URL = process.env.VUE_APP_EVOLUTION_URL;
const N8N_AGENTS = process.env.VUE_APP_N8N_CHATWOOT_AGENTS;

function viewMetrics(accountId) {
    if (!accountId) {
        console.warn('Account ID não está disponível para esta instância.');
        return;
    }
    console.log('Redirecionando para Metrics com Account ID:', accountId);
    router.push({ name: 'Metrics', params: { id: accountId } });
}


// Função para buscar os agentes de uma conta
async function fetchAgents(accountId, token) {
    try {
        const response = await fetch(`${N8N_AGENTS}`, {
            method: 'GET',
            headers: {
                api_access_token: `${token.token}`,
                account_id: `${accountId}`,
                Accept: `application/json, text/plain, */*`,
                Host: `${EVOLUTION_URL}`
            },
        });

        if (!response.ok) {
            throw new Error(`Erro ao buscar agentes para a conta ${accountId}: ${response.statusText}`);
        }

        // Supondo que a API retorna um objeto, com os agentes em uma propriedade chamada `data`
        const data = await response.json();

        if (!Array.isArray(data)) {
            console.error('A resposta da API não é um array. Verifique o formato retornado.');
            return [];
        }

        return data; // Retorna o array diretamente
    } catch (error) {
        console.error(`Erro ao buscar agentes para a conta ${accountId}:`, error.message);
        return []; // Retorna um array vazio em caso de erro
    }
}

const tooltips = ref({ online: null, offline: null });

// Função para mostrar o tooltip
function showTooltip(type, instanceId) {
    tooltips.value[type] = instanceId;
}

// Função para manter o tooltip aberto ao passar o cursor ou tocar na lista
function keepTooltipOpen(type, instanceId) {
    tooltips.value[type] = instanceId;
}

// Função para esconder o tooltip
function hideTooltip(type, instanceId) {
    // Usar um timeout para evitar fechamento imediato
    setTimeout(() => {
        if (tooltips.value[type] === instanceId) {
            tooltips.value[type] = null;
        }
    }, 5000);
}


// Atualize `fetchInstances` para incluir listas de usuários online/offline
async function fetchInstances() {
    try {
        isLoading.value = true;

        const response = await fetch(`${EVOLUTION_URL}/instance/fetchInstances`, {
            method: 'GET',
            headers: {
                apikey: EVOLUTION_API,
            },
        });

        if (!response.ok) {
            throw new Error(`Erro ao buscar instâncias: ${response.statusText}`);
        }

        const data = await response.json();

        const updatedInstances = await Promise.all(
            data.map(async (item) => {
                const chatwoot = item.Chatwoot || {};
                const accountId = chatwoot.accountId || null; // Garante que seja atribuído mesmo que não exista

                let agentStats = { activeUsers: 0, inactiveUsers: 0, onlineUsers: [], offlineUsers: [] };

                if (chatwoot.enabled && accountId) {
                    const agents = await fetchAgents(accountId, {
                        url: chatwoot.url,
                        token: chatwoot.token,
                    });

                    agentStats = {
                        activeUsers: agents.filter(agent => agent.availability_status === 'online').length,
                        inactiveUsers: agents.filter(agent => agent.availability_status === 'offline').length,
                        onlineUsers: agents.filter(agent => agent.availability_status === 'online'),
                        offlineUsers: agents.filter(agent => agent.availability_status === 'offline'),
                    };
                }

                return {
                    id: item.id,
                    name: item.name,
                    connectionStatus: item.connectionStatus,
                    profilePicUrl: item.profilePicUrl,
                    accountId: accountId, // Adiciona accountId diretamente à instância
                    ownerJid: item.ownerJid, // <-- Aqui está a correção
                    ...agentStats,
                };
            })
        );

        

        instances.value = updatedInstances;
    } catch (error) {
        console.error('Erro no fetchInstances:', error.message);
    } finally {
        isLoading.value = false;
    }
}

function formatPhoneNumber(jid) {
    if (!jid) return 'Número não cadastrado';

    // Remover caracteres não numéricos e partes desnecessárias como '@s.whatsapp.net'
    const number = jid.replace(/\D/g, '').replace(/(@s.whatsapp.net|@c.us)/g, '');

    // Verifica se o número tem 13 dígitos (DDI + DDD + Número)
    if (number.length === 13 && number.startsWith('55')) {
        // Remove o DDI (55) e mantém apenas DDD + número
        return `(${number.substring(2, 4)}) ${number.substring(4, 9)}-${number.substring(9)}`;
    } else if (number.length === 12 && number.startsWith('55')) {
        // Remove o DDI (55) para números com 12 dígitos
        return `(${number.substring(2, 4)}) ${number.substring(4, 8)}-${number.substring(8)}`;
    } else if (number.length === 11) {
        // Para números nacionais sem DDI
        return `(${number.substring(0, 2)}) ${number.substring(2, 7)}-${number.substring(7)}`;
    } else if (number.length === 10) {
        // Para números fixos (sem nono dígito)
        return `(${number.substring(0, 2)}) ${number.substring(2, 6)}-${number.substring(6)}`;
    }

    // Retorna o próprio número se o formato for inesperado
    return number;
}



// Fallback para imagem em caso de erro
function fallbackImage(event) {
    event.target.src = 'https://placehold.co/400';
}

onMounted(() => {
    // Carregar dados inicialmente
    fetchInstances();

    // Atualizar dados a cada 1 minuto
    intervalId = setInterval(() => {
        fetchInstances();
    }, 60000); // 60.000 ms = 1 minuto
});

onUnmounted(() => {
    // Limpar o intervalo ao desmontar o componente
    if (intervalId) {
        clearInterval(intervalId);
    }
});

</script>


<style>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
</style>
