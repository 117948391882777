<template>
    <BaseTemplate app-name="Gestão de Ativos" page-title="">
        <template #content>
            <div class="container mx-auto px-4 py-8">
                <!-- Abas -->
                <div class="mb-4 border-b border-gray-200">
                    <ul class="flex flex-wrap -mb-px">
                        <li class="mr-2">
                            <button @click="activeTab = 'equipments'" :class="['inline-block py-4 px-4 text-sm font-medium text-center rounded-t-lg',
                                activeTab === 'equipments'
                                    ? 'text-blue-600 border-b-2 border-blue-600 active'
                                    : 'text-gray-500 hover:text-gray-600 hover:border-gray-300']">
                                Equipamentos
                            </button>
                        </li>
                        <li class="mr-2">
                            <button @click="activeTab = 'kits'" :class="['inline-block py-4 px-4 text-sm font-medium text-center rounded-t-lg',
                                activeTab === 'kits'
                                    ? 'text-blue-600 border-b-2 border-blue-600 active'
                                    : 'text-gray-500 hover:text-gray-600 hover:border-gray-300']">
                                Kits de Equipamentos
                            </button>
                        </li>
                    </ul>
                </div>

                <!-- Conteúdo das abas -->
                <div v-if="activeTab === 'equipments'">
                    <div class="flex justify-between items-center mb-4">
                        <h2 class="text-2xl font-bold">Equipamentos</h2>
                        <button @click="showAddEquipmentModal = true" class="btn btn-primary">
                            <Plus class="w-5 h-5 mr-2" />
                            Adicionar Equipamento
                        </button>
                    </div>

                    <!-- Filtros para Equipamentos -->
                    <div class="mb-4 grid grid-cols-1 md:grid-cols-3 gap-4">
                        <input v-model="filters.equipment.name" type="text" placeholder="Filtrar por nome"
                            class="w-full px-4 py-2 border rounded-lg" />
                        <input v-model="filters.equipment.type" type="text" placeholder="Filtrar por tipo"
                            class="w-full px-4 py-2 border rounded-lg" />
                        <select v-model="filters.equipment.status" class="w-full px-4 py-2 border rounded-lg">
                            <option value="">Todos os status</option>
                            <option value="Disponível">Disponível</option>
                            <option value="Em uso">Em uso</option>
                            <option value="Em manutenção">Em manutenção</option>
                        </select>
                    </div>

                    <!-- Tabela de Equipamentos -->
                    <div class="overflow-x-auto">
                        <table class="min-w-full bg-white">
                            <thead class="bg-gray-100">
                                <tr>
                                    <th class="py-2 px-4 border-b text-left">Nome</th>
                                    <th class="py-2 px-4 border-b text-left">Tipo</th>
                                    <th class="py-2 px-4 border-b text-left">Status</th>
                                    <th class="py-2 px-4 border-b text-left">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="equipment in filteredEquipments" :key="equipment.id"
                                    class="hover:bg-gray-50">
                                    <td class="py-2 px-4 border-b">{{ equipment.name }}</td>
                                    <td class="py-2 px-4 border-b">{{ equipment.type }}</td>
                                    <td class="py-2 px-4 border-b">{{ equipment.status }}</td>
                                    <td class="py-2 px-4 border-b">
                                        <button @click="editEquipment(equipment)" class="btn btn-icon btn-sm mr-2"
                                            title="Editar">
                                            <Edit2 class="w-4 h-4" />
                                        </button>
                                        <button @click="deleteEquipment(equipment)"
                                            class="btn btn-icon btn-sm btn-danger" title="Excluir">
                                            <Trash2 class="w-4 h-4" />
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div v-if="activeTab === 'kits'">
                    <div class="flex justify-between items-center mb-4">
                        <h2 class="text-2xl font-bold">Kits de Equipamentos</h2>
                        <button @click="showAddKitModal = true" class="btn btn-primary">
                            <Plus class="w-5 h-5 mr-2" />
                            Adicionar Kit
                        </button>
                    </div>

                    <!-- Filtros para Kits -->
                    <div class="mb-4">
                        <input v-model="filters.kit.name" type="text" placeholder="Filtrar por nome do kit"
                            class="w-full px-4 py-2 border rounded-lg" />
                    </div>

                    <!-- Tabela de Kits -->
                    <div class="overflow-x-auto">
                        <table class="min-w-full bg-white">
                            <thead class="bg-gray-100">
                                <tr>
                                    <th class="py-2 px-4 border-b text-left">Nome do Kit</th>
                                    <th class="py-2 px-4 border-b text-left">Itens</th>
                                    <th class="py-2 px-4 border-b text-left">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="kit in filteredKits" :key="kit.id" class="hover:bg-gray-50">
                                    <td class="py-2 px-4 border-b">{{ kit.name }}</td>
                                    <td class="py-2 px-4 border-b">{{ kit.items.join(', ') }}</td>
                                    <td class="py-2 px-4 border-b">
                                        <button @click="editKit(kit)" class="btn btn-icon btn-sm mr-2" title="Editar">
                                            <Edit2 class="w-4 h-4" />
                                        </button>
                                        <button @click="deleteKit(kit)" class="btn btn-icon btn-sm btn-danger"
                                            title="Excluir">
                                            <Trash2 class="w-4 h-4" />
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </template>
    </BaseTemplate>

    <!-- Modal para adicionar equipamento -->
    <Modal v-if="showAddEquipmentModal" @close="showAddEquipmentModal = false">
        <template #header>Adicionar Novo Equipamento</template>
        <template #body>
            <form @submit.prevent="addEquipment">
                <div class="mb-4">
                    <label for="equipmentName" class="block text-sm font-medium text-gray-700">Nome</label>
                    <input type="text" id="equipmentName" v-model="newEquipment.name" required
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                </div>
                <div class="mb-4">
                    <label for="equipmentType" class="block text-sm font-medium text-gray-700">Tipo</label>
                    <input type="text" id="equipmentType" v-model="newEquipment.type" required
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                </div>
                <div class="mb-4">
                    <label for="equipmentStatus" class="block text-sm font-medium text-gray-700">Status</label>
                    <select id="equipmentStatus" v-model="newEquipment.status" required
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                        <option value="Disponível">Disponível</option>
                        <option value="Em uso">Em uso</option>
                        <option value="Em manutenção">Em manutenção</option>
                    </select>
                </div>
                <div class="flex justify-end">
                    <button type="submit" class="btn btn-primary">Adicionar</button>
                </div>
            </form>
        </template>
    </Modal>

    <!-- Modal para adicionar kit -->
    <Modal v-if="showAddKitModal" @close="showAddKitModal = false">
        <template #header>Adicionar Novo Kit</template>
        <template #body>
            <form @submit.prevent="addKit">
                <div class="mb-4">
                    <label for="kitName" class="block text-sm font-medium text-gray-700">Nome do Kit</label>
                    <input type="text" id="kitName" v-model="newKit.name" required
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                </div>
                <div class="mb-4">
                    <label for="kitItems" class="block text-sm font-medium text-gray-700">Itens (separados por
                        vírgula)</label>
                    <input type="text" id="kitItems" v-model="newKit.items" required
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                </div>
                <div class="flex justify-end">
                    <button type="submit" class="btn btn-primary">Adicionar</button>
                </div>
            </form>
        </template>
    </Modal>
</template>

<script setup>
import { ref, computed } from 'vue';
import BaseTemplate from './BaseTemplate.vue';
import { Plus, Edit2, Trash2 } from 'lucide-vue-next';

const activeTab = ref('equipments');
const equipments = ref([
    { id: 1, name: 'Laptop Dell XPS', type: 'Notebook', status: 'Em uso' },
    { id: 2, name: 'Monitor LG 27"', type: 'Monitor', status: 'Disponível' },
    { id: 3, name: 'iPhone 13', type: 'Smartphone', status: 'Em manutenção' },
]);

const equipmentKits = ref([
    { id: 1, name: 'Kit Desenvolvedor', items: ['Laptop', 'Monitor', 'Teclado', 'Mouse'] },
    { id: 2, name: 'Kit Escritório', items: ['Desktop', 'Monitor', 'Telefone IP'] },
]);

const filters = ref({
    equipment: {
        name: '',
        type: '',
        status: ''
    },
    kit: {
        name: ''
    }
});

const showAddEquipmentModal = ref(false);
const showAddKitModal = ref(false);

const newEquipment = ref({ name: '', type: '', status: 'Disponível' });
const newKit = ref({ name: '', items: '' });

const filteredEquipments = computed(() => {
    return equipments.value.filter(equipment =>
        equipment.name.toLowerCase().includes(filters.value.equipment.name.toLowerCase()) &&
        equipment.type.toLowerCase().includes(filters.value.equipment.type.toLowerCase()) &&
        (filters.value.equipment.status === '' || equipment.status === filters.value.equipment.status)
    );
});

const filteredKits = computed(() => {
    return equipmentKits.value.filter(kit =>
        kit.name.toLowerCase().includes(filters.value.kit.name.toLowerCase())
    );
});

const addEquipment = () => {
    equipments.value.push({
        id: equipments.value.length + 1,
        ...newEquipment.value
    });
    newEquipment.value = { name: '', type: '', status: 'Disponível' };
    showAddEquipmentModal.value = false;
};

const addKit = () => {
    equipmentKits.value.push({
        id: equipmentKits.value.length + 1,
        name: newKit.value.name,
        items: newKit.value.items.split(',').map(item => item.trim())
    });
    newKit.value = { name: '', items: '' };
    showAddKitModal.value = false;
};

const editEquipment = (equipment) => {
    // Implementar lógica de edição
    console.log('Editar equipamento:', equipment);
};

const deleteEquipment = (equipment) => {
    // Implementar lógica de exclusão
    console.log('Excluir equipamento:', equipment);
};

const editKit = (kit) => {
    // Implementar lógica de edição
    console.log('Editar kit:', kit);
};

const deleteKit = (kit) => {
    // Implementar lógica de exclusão
    console.log('Excluir kit:', kit);
};
</script>

<style>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.btn {
    @apply font-bold py-2 px-4 rounded;
}

.btn-primary {
    @apply bg-blue-500 text-white;
}

.btn-primary:hover {
    @apply bg-blue-700;
}

.btn-icon {
    @apply p-2 rounded-full hover:bg-gray-200;
}

.btn-sm {
    @apply py-1 px-2 text-sm;
}

.btn-danger {
    @apply bg-red-500 text-white hover:bg-red-700;
}
</style>