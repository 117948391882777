<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { initializeApp } from "firebase/app";


const isOnline = ref(true);
const router = useRouter();
const isDark = ref(false);
const updateOnlineStatus = () => {
  isOnline.value = navigator.onLine;
  if (!isOnline.value) {
    router.push({ name: 'Offline' });
  }
};

onMounted(() => {
  // Verifica se já existe preferência no localStorage
  const storedDarkMode = localStorage.getItem('darkMode');
  if (storedDarkMode === null) {
    // Primeiro acesso: utiliza a preferência do sistema
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    isDark.value = prefersDark;
    localStorage.setItem('darkMode', prefersDark);
  } else {
    isDark.value = storedDarkMode === 'true';
  }
  // Aplica a classe "dark" conforme o valor definido
  document.documentElement.classList.toggle('dark', isDark.value);
});

const firebaseConfig = {
  apiKey: "AIzaSyCoqlyEpTZcZuDEddDZwto0_PHrsSpICws",
  authDomain: "testedashboard-dd576.firebaseapp.com",
  projectId: "testedashboard-dd576",
  storageBucket: "testedashboard-dd576.firebasestorage.app",
  messagingSenderId: "982498656380",
  appId: "1:982498656380:web:b9996dddddfe07bed189f9",
  measurementId: "G-T0TR2BTFL9"
};

onMounted(() => {
  window.addEventListener("online", updateOnlineStatus);
  window.addEventListener("offline", updateOnlineStatus);
  initializeApp(firebaseConfig)
});

onBeforeUnmount(() => {
  window.removeEventListener("online", updateOnlineStatus);
  window.removeEventListener("offline", updateOnlineStatus);
});
</script>

<template>
  <router-view />
</template>
