<template>
    <BaseTemplate app-name="Servidores" page-title="">
        <template #content>
            <div class="container mx-auto px-4 py-6">
                <!-- Header Section -->


                <!-- Error Message -->
                <div v-if="error"
                    class="mb-6 p-4 bg-red-50 dark:bg-red-900 border border-red-200 dark:border-red-800 text-red-700 dark:text-red-300 rounded-lg flex items-center gap-3 shadow-sm">
                    <AlertCircle class="h-5 w-5 text-red-500 dark:text-red-300" />
                    <p class="font-medium">{{ error }}</p>
                </div>

                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-6">
                    <div v-for="(count, category) in getStatusSummary()" :key="category"
                        class="bg-white dark:bg-gray-800 rounded-lg p-4 shadow-sm border border-gray-100 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700 hover:scale-105 cursor-pointer transition-all duration-200"
                        @click="filterByCategory(category)">
                        <div class="flex items-center justify-between">
                            <span class="flex items-center gap-2 text-gray-700 dark:text-gray-300">
                                <component :is="getStatusIcon(category)" :class="getStatusIconColor(category)"
                                    class="h-5 w-5" />
                                {{ category }}
                            </span>
                            <span class="text-lg font-bold text-gray-700 dark:text-gray-300">{{ count }}</span>
                        </div>
                    </div>



                    <!-- Last Update Card -->
                    <div
                        class="bg-white dark:bg-gray-800 rounded-lg p-4 shadow-sm border border-gray-100 dark:border-gray-700 hover:shadow-md transition-shadow duration-200">
                        <div class="flex items-center justify-between">
                            <span class="flex items-center gap-2 text-gray-700 dark:text-gray-300">
                                <Calendar class="h-5 w-5 text-blue-600 dark:text-blue-300" />
                                Última Atualização
                            </span>
                            <span class="text-sm font-bold text-gray-700 dark:text-gray-300">
                                {{ lastUpdate ? formatDate(lastUpdate) : "N/A" }}
                            </span>
                        </div>
                    </div>
                </div>

                <transition name="fade-scale">
                    <div v-show="selectedCategory" class="flex justify-center mb-6 transition-opacity duration-300">
                        <button @click="clearFilter"
                            class="text-sm font-medium bg-blue-500 text-white px-3 py-1 rounded-md shadow hover:bg-blue-600 transition">
                            Limpar Filtro: {{ selectedCategory }}
                        </button>
                    </div>
                </transition>
                <transition name="fade-scale">
                    <!-- Servers Grid -->
                    <div v-if="filterLoading" class="text-center py-4">
                        <RefreshCw class="h-16 w-16 animate-spin text-blue-600 dark:text-blue-400 mx-auto" />
                    </div>

                    <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        <div v-for="servidor in filteredServidores" :key="servidor.id"
                            class="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-100 dark:border-gray-700 hover:shadow-md transition-all duration-200">
                            <div class="p-5">
                                <!-- Header with Status -->
                                <div class="flex justify-between items-start mb-4">
                                    <div class="flex items-center gap-3">
                                        <div class="bg-gray-100 dark:bg-gray-700 p-2 rounded-lg">
                                            <Building2 class="h-5 w-5 text-gray-600 dark:text-gray-300" />
                                        </div>
                                        <div>
                                            <h2 class="font-semibold text-gray-800 dark:text-gray-200">{{
                                                servidor.unidade_nome }}</h2>
                                        </div>
                                    </div>
                                    <span
                                        class="flex items-center mt-2 gap-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                                        <component :is="getStatusIcon(statusText(servidor.status))"
                                            :class="getStatusIconColor(statusText(servidor.status))" class="h-5 w-5" />
                                        <span class="hidden md:inline">
                                            {{ statusText(servidor.status) }}
                                        </span>
                                    </span>


                                </div>

                                <!-- Links Section -->
                                <div class="space-y-4">
                                    <!-- Primary Link -->
                                    <div :class="getLinkCardClass(servidor?.status, 'primary')"
                                        class="relative overflow-hidden rounded-lg p-4 border border-gray-200 dark:border-gray-700 shadow-sm transition hover:shadow-lg">
                                        <div class="flex items-center gap-4">
                                            <!-- Status do Link Primário -->
                                            <div
                                                class="flex items-center justify-center w-10 h-10 rounded-full bg-gray-100 dark:bg-gray-700">
                                                <CheckCircle v-if="servidor?.status === 1 || servidor?.status === 2"
                                                    class="h-6 w-6 text-green-600 dark:text-green-400" />
                                                <XCircle v-else class="h-6 w-6 text-red-600 dark:text-white" />
                                            </div>
                                            <div class="flex-1">
                                                <p class="font-medium text-gray-700 dark:text-gray-200">Link Primário
                                                </p>
                                                <p class="text-sm text-gray-600 dark:text-gray-400 mt-1">
                                                    {{ servidor?.primaryName || "Desconhecido" }}
                                                    <span class="text-gray-400 dark:text-gray-500">({{
                                                        servidor?.primaryIP || "N/A" }})</span>
                                                </p>
                                            </div>
                                            <!-- Sync Indicator for Primary -->
                                            <div
                                                class="flex items-center justify-center w-10 h-10 rounded-full bg-gray-100 dark:bg-gray-700">
                                                <RefreshCw
                                                    v-if="servidor?.primaryIP && servidor?.sync_primario === 'on'"
                                                    class="h-6 w-6 text-blue-600 dark:text-blue-400 animate-spin" />
                                                <RefreshCwOff
                                                    v-else-if="servidor?.primaryIP && servidor?.sync_primario === 'off'"
                                                    class="h-6 w-6 text-red-600 dark:text-red-400" />
                                                <RefreshCwOff v-else class="h-6 w-6 text-gray-400 dark:text-gray-500" />
                                            </div>
                                            <!-- Button for Primary Link -->
                                            <button v-if="showGlobeButton(servidor?.status, 'primary')"
                                                @click="openLink(servidor?.dns)"
                                                class=" bg-blue-500 hover:bg-blue-600 text-white rounded-full p-2 shadow transition">
                                                <Globe class="h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>

                                    <!-- Secondary Link -->
                                    <div :class="getLinkCardClass(servidor?.status ?? 4, 'secondary', servidor)"
                                        class="relative overflow-hidden rounded-lg p-4 border border-gray-200 dark:border-gray-700 shadow-sm transition hover:shadow-lg">
                                        <div class="flex items-center gap-4">
                                            <!-- Status do Link Secundário -->
                                            <div
                                                class="flex items-center justify-center w-10 h-10 rounded-full bg-gray-100 dark:bg-gray-700">
                                                <XCircle v-if="!servidor?.secondaryIP || !servidor?.secondaryName"
                                                    class="h-6 w-6 text-gray-400 dark:text-gray-500" />
                                                <CheckCircle
                                                    v-else-if="servidor?.status === 1 || servidor?.status === 3"
                                                    class="h-6 w-6 text-green-600 dark:text-green-400" />
                                                <XCircle v-else class="h-6 w-6 text-red-600 dark:text-white" />
                                            </div>
                                            <div class="flex-1">
                                                <p class="font-medium text-gray-700 dark:text-gray-200">Link Secundário
                                                </p>
                                                <p class="text-sm text-gray-600 dark:text-gray-400 mt-1">
                                                    {{ servidor?.secondaryName || "Desconhecido" }}
                                                    <span class="text-gray-400 dark:text-gray-500">({{
                                                        servidor?.secondaryIP || "N/A" }})</span>
                                                </p>
                                            </div>
                                            <!-- Sync Indicator for Secondary -->
                                            <div
                                                class="flex items-center justify-center w-10 h-10 rounded-full bg-gray-100 dark:bg-gray-700">
                                                <RefreshCw
                                                    v-if="servidor?.secondaryIP && servidor?.sync_secundario === 'on'"
                                                    class="h-6 w-6 text-blue-600 dark:text-blue-400 animate-spin" />
                                                <RefreshCwOff
                                                    v-else-if="servidor?.secondaryIP && servidor?.sync_secundario === 'off'"
                                                    class="h-6 w-6 text-red-600 dark:text-red-400" />
                                                <RefreshCwOff v-else class="h-6 w-6 text-gray-400 dark:text-gray-500" />
                                            </div>
                                        </div>
                                    </div>
                                </div>






                                <!-- Validation Date 
                            <div
                                class="mt-4 flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400 pt-4 border-t border-gray-200 dark:border-gray-600">
                                <Calendar class="h-4 w-4" />
                                <span>Atualizado em: {{ formatDate(servidor.validation_date) }}</span>
                        </div> -->
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <p class="text-center text-sm mb-6 text-gray-500 dark:text-gray-400">
                © 2025 T.I CCDTI. Todos os direitos reservados.
            </p>
        </template>
    </BaseTemplate>
</template>


<script setup>
import BaseTemplate from './BaseTemplate.vue';
import { ref, onMounted, computed, onUnmounted} from "vue";
import axios from "axios";
import {
    RefreshCwOff,
    RefreshCw,
    AlertCircle,
    Building2,
    Calendar,
    Monitor,
    MonitorCheck,
    MonitorX,
    CheckCircle,
    XCircle,
    Globe

} from 'lucide-vue-next';


const servidores = ref([]);
const isLoading = ref(false);
const error = ref(null);
let autoUpdateInterval = null; // Intervalo para auto-atualização

const lastUpdate = computed(() => {
    if (servidores.value.length === 0) return null;
    const dates = servidores.value.map(s => new Date(s.validation_date));
    return new Date(Math.max(...dates));
});

async function fetchServidores() {
    isLoading.value = true;
    error.value = null;

    try {
        const token = sessionStorage.getItem('api_token');
        const response = await axios.get("/api/v1/scan/link", {
            headers: {
                'X-API-KEY': token,
            },
        });
        servidores.value = response.data;
    } catch (err) {
        error.value = "Erro ao carregar os servidores. Tente novamente mais tarde.";
        console.error(err);
    } finally {
        isLoading.value = false;
    }
}

const showGlobeButton = (status, linkType) => {
    if (status === 1 && linkType === "primary") {
        // Ambos UP: Botão no link primário
        return true;
    } else if (status === 2 && linkType === "primary") {
        // Primário UP: Botão no link primário
        return true;
    } else if (status === 3 && linkType === "secondary") {
        // Secundário UP: Botão no link secundário
        return true;
    }
    return false;
};

const openLink = (dns) => {
    window.open(`https://${dns}/Place`, "_blank");
};


const getLinkCardClass = (status, linkType, servidor) => {
    if (linkType === "secondary" && (!servidor.secondaryIP || servidor.secondaryName === null)) {
        // Se o link secundário estiver vazio ou sem nome, aplicar estilo cinza
        return "bg-gray-300 dark:bg-gray-700 border border-gray-400 dark:border-gray-600 shadow hover:shadow-lg transition";
    }

    if (status === 1) {
        // Ambos UP: Verde para ambos
        return "bg-gradient-to-r from-green-50 to-green-100 dark:from-green-800 dark:to-green-700 border border-green-300 dark:border-green-600 shadow-lg hover:shadow-xl transition";
    } else if (status === 2 && linkType === "primary") {
        // Funcionamento Parcial: Primário UP
        return "bg-gradient-to-r from-green-50 to-green-100 dark:from-green-800 dark:to-green-700 border border-green-300 dark:border-green-600 shadow-lg hover:shadow-xl transition";
    } else if (status === 3 && linkType === "secondary") {
        // Funcionamento Parcial: Secundário UP
        return "bg-gradient-to-r from-green-50 to-green-100 dark:from-green-800 dark:to-green-700 border border-green-300 dark:border-green-600 shadow-lg hover:shadow-xl transition";
    } else if (status === 4 || (status === 2 && linkType === "secondary") || (status === 3 && linkType === "primary")) {
        // Fora de Operação ou Link Inativo no Funcionamento Parcial
        return "bg-gradient-to-r from-red-50 to-red-100 dark:from-red-800 dark:to-red-700 border border-red-300 dark:border-red-600 shadow-lg hover:shadow-xl transition";
    }

    // Default (Caso nenhuma condição seja atendida)
    return "bg-gradient-to-r from-gray-50 to-gray-100 dark:from-gray-800 dark:to-gray-700 border border-gray-300 dark:border-gray-600 shadow hover:shadow-lg transition";
};






const statusText = (status) => {
    const statuses = {
        1: "Pleno Funcionamento",
        2: "Funcionamento Parcial",
        3: "Funcionamento Parcial",
        4: "Fora de Operação",
    };
    return statuses[status] || "Desconhecido";
};


const getStatusIcon = (category) => {
    const icons = {
        "Pleno Funcionamento": MonitorCheck,
        "Funcionamento Parcial": Monitor,
        "Fora de Operação": MonitorX,
    };
    return icons[category] || Monitor;
};

const getStatusIconColor = (category) => {
    const colors = {
        "Pleno Funcionamento": "text-green-600 dark:text-green-400",
        "Funcionamento Parcial": "text-yellow-600 dark:text-yellow-400",
        "Fora de Operação": "text-red-600 dark:text-red-400",
    };
    return colors[category] || "text-gray-600 dark:text-gray-400";
};



const getStatusSummary = () => {
    const summary = {
        "Pleno Funcionamento": 0,
        "Funcionamento Parcial": 0,
        "Fora de Operação": 0,
    };

    servidores.value.forEach((servidor) => {
        if (servidor.status === 1) {
            summary["Pleno Funcionamento"]++;
        } else if (servidor.status === 2 || servidor.status === 3) {
            summary["Funcionamento Parcial"]++;
        } else if (servidor.status === 4) {
            summary["Fora de Operação"]++;
        }
    });

    return summary;
};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    });
};


const selectedCategory = ref(null);

const filterLoading = ref(false);

const filterByCategory = async (category) => {
    selectedCategory.value = category;
    filterLoading.value = true;
    setTimeout(() => {
        filterLoading.value = false;
    }, 500); // Simula o tempo de carregamento
};


const clearFilter = () => {
    selectedCategory.value = null;
    filterLoading.value = false;
};

const filteredServidores = computed(() => {
    if (!selectedCategory.value) {
        return servidores.value;
    }

    return servidores.value.filter((servidor) => {
        const status = statusText(servidor.status);

        if (selectedCategory.value === "Funcionamento Parcial") {
            return status === "Primário UP" || status === "Secundário UP" || status === "Funcionamento Parcial";
        }

        return status === selectedCategory.value;
    });
});


// Inicia o intervalo de auto-atualização
const startAutoUpdate = () => {
    autoUpdateInterval = setInterval(() => {
        const now = new Date();
        if (now.getMinutes() % 5 === 0) {
            fetchServidores();
        }
    }, 60 * 1000); // Verifica a cada 1 minuto
};


onMounted(() => {
    fetchServidores();
    startAutoUpdate();
});

onUnmounted(() => {
    if (autoUpdateInterval) {
        clearInterval(autoUpdateInterval);
    }
});
</script>

<style>
.hover:shadow-xl {
    transition: box-shadow 0.3s ease-in-out;
}

.transition {
    transition: all 0.3s ease-in-out;
}
</style>
